import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreenButton, faGlobe, faUserGear } from '@fortawesome/free-solid-svg-icons'

const SupportAdminPage = () => {
	return (
		<Layout pageTitle="Support kring Brfify-admin">

            <p className="intro">Inuti admin finns hjälptexter och information på varje sida under rubriken "Tips &amp; info" samt på sidorna "Tips" och "Support &amp; Nyheter" som du hittar längst ner i menyn.</p>

			<h2>Hur kommer jag åt admin?</h2>
			<p>Brfify-admin finns på webbadressen <a href="https://admin.brfify.se" target="_blank">admin.brfify.se</a> och kräver inloggning av en redan registerad administratör. Om du saknar åtkomst be någon befintlig administratör att lägga till dig.</p>


			<h2>Glömt lösenord</h2>
			<p>Om du som befintlig administratör har glömt ditt lösenord kan själv återställa det genom att få en länk till din e-postadress. På inloggningssidan klicka på "Glömt lösenord?" och följ instruktionerna. Av säkerhetsskäl finns det begränsningar i antal lösenordsåterställningar.</p>


			<h2>Finns admin som app?</h2>
			<p>Nej, admin är webbaserat och fungerar väl på datorer, surfplattor och mobiler med moderna webbläsare. Alla användare rekommenderas att använda senaste versionerna av operativsystem och webbläsare.</p>

		</Layout>
	)
}

export default SupportAdminPage